import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { URL } from '../../components/constant';



const EventTabs = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [applied, setApplied] = useState(false);
    const [questioned, setQuestioned] = useState(false);
    const [data, setData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        reason: '',
        gender: '',
        age: '',
        education: '',
        computer: '',
        language: '',
        programming: '',
        participated: '',
        employmentStatus: '',
        profLink: ''
      })
      const [questions, setQuestions] = useState({
        fullName: '',
        email: '',
        message: ''
      })

      useEffect(() => {

        var script = document.createElement("script");
        script.id = "ff-script";
        script.src = "https://formfacade.com/include/107695094156741955830/form/1FAIpQLScU91r6qMGEXuig1EQtAXU3WqtyS7W-TUeYj6V6Phcu8dMoGw/classic.js?div=ff-compose";
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);
    
      }, []);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    const SubmitHandler = (e) => {
        e.preventDefault()
    }


    const handleValueChanges = e => { 
        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
    }

    const handleQuestionValueChanges = e => { 
        setQuestions({
          ...questions,
          [e.target.name]: e.target.value,
        });
    }



    const handleSaveQuestions = (e) => {
        e.preventDefault()

        axios.post(`${URL}/questions`, {
            name: questions.fullName,
            message: questions.message,
            email: questions.email
        }, {
          headers: { 'Content-Type': 'application/json' }
        }).then((res) => {      
            console.log(res.data)
            setQuestioned(true)
            setQuestions({
                fullName: '',
                email: '',
                message: ''
              })
        }).catch((err) => {
            console.log(err.response)
        })
      }


    const handleSaveApplications = (e) => {
        e.preventDefault()

        if(data.fname.length === 0 || data.lname.length === 0 || data.email.length === 0 
            || data.phone.length === 0 || data.reason.length === 0
            || data.gender.length === 0 || data.age.length === 0 || data.education.length === 0
            || data.computer.length === 0 || data.language.length === 0 || data.programming.length === 0
            || data.participated.length === 0 || data.employmentStatus.length === 0 || data.profLink.length === 0) {
                alert("Missing fields. You must answer all the questions to submit.")
            } else {
                axios.post(`${URL}/submisions`, {
                    firstname: data.fname,
                    lastname: data.lname,
                    email: data.email,
                    phone: data.phone,
                    reason: data.reason,
                    gender: data.gender,
                    age: data.age,
                    education: data.education,
                    computer: data.computer,
                    language: data.language,
                    programming: data.programming,
                    participation: data.participated,
                    employment: data.employmentStatus,
                    professionallink: data.profLink
                }, {
                  headers: { 'Content-Type': 'application/json' }
                }).then((res) => {      
                    console.log(res.data)
                    setApplied(true)
                    setData({
                        fname: '',
                        lname: '',
                        email: '',
                        phone: '',
                        reason: '',
                        gender: '',
                        age: '',
                        education: '',
                        computer: '',
                        language: '',
                        programming: '',
                        participated: '',
                        employmentStatus: '',
                        profLink: ''
                      })
                }).catch((err) => {
                    console.log(err.response)
                })
            }

      }



    return (
        <div className="wpo-event-details-wrap">
            <div className="wpo-event-details-tab">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Event Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >

                            Apply Now
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >

                            Have Questions
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>

            <div className="wpo-event-details-content">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <div id="Schedule" className="tab-pane active">
                                    <h1>Event Date</h1>
                                    {props.eventDate.startDate == props.eventDate.endDate ? <p>{props.eventDate.startDate}</p> : <p>{`${props.eventDate.startDate} - ${props.eventDate.endDate}`}</p>}

                                    <h1>What you will learn?</h1>
                                    <p>{props.description}</p>
                                    
                                    <h3>Stack</h3>
                                    <ul>
                                        {props.stack.stack1 ? <li>{props.stack.stack1}</li> : ''} 
                                        {props.stack.stack2 ? <li>{props.stack.stack2}</li> : ''}
                                        {props.stack.stack3 ? <li>{props.stack.stack3}</li> : ''}
                                        {props.stack.stack4 ? <li>{props.stack.stack4}</li> : ''}
                                        {props.stack.stack5 ? <li>{props.stack.stack5}</li> : ''}
                                        {props.stack.stack6 ? <li>{props.stack.stack6}</li> : ''}
                                        {props.stack.stack7 ? <li>{props.stack.stack7}</li> : ''}
                                    </ul>

                                    <h3>Timeline</h3>
                                    <ul>
                                        {props.timeline.submission ? <li>{props.timeline.submission}</li> : ''}
                                        {props.timeline.review ? <li>{props.timeline.review}</li> : ''}
                                        {props.timeline.interview ? <li>{props.timeline.interview}</li> : ''}
                                        {props.timeline.status ? <li>{props.timeline.status}</li> : ''}
                                        {props.timeline.launch ? <li>{props.timeline.launch}</li> : ''}
                                    </ul>

                                    {props.prize ? (
                                        <div>
                                         <h3>Cash Prizes for best projects</h3>
                                         <ul>
                                             <li>First Place Winner: ${props.prize.first} USD</li>
                                             <li>Second Place Winner: ${props.prize.second} USD</li>
                                             <li>Third Place Winner: ${props.prize.third} USD</li>
                                         </ul>
                                         </div>
                                    ) : ''}

                                    {props.criteria ? (
                                        <div>
                                         <h3>Who can apply?</h3>
                                         <ul>
                                             <li>Must be 18 years and above</li>
                                             <li>Second Place Winner: ${props.prize.second} USD</li>
                                             <li>Third Place Winner: ${props.prize.third} USD</li>
                                         </ul>
                                         </div>
                                    ) : ''}

                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <div id="ff-compose"></div>
                    </TabPane>
                    <TabPane tabId="3">
                        {questioned ? <h2>Thank you for your question. We will get back to you as soon as possible.</h2> : (
                            <Row>
                            <Col sm="12">
                                <div className="event-contact">
                                    <div className="wpo-donations-details">
                                        <form method="post" className="contact-validation-active" id="contact-form-main" onSubmit={handleSaveQuestions}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                    <input type="text" className="form-control" name="fullName" id="fullName" placeholder="Your Name*" value={questions.fullName} onChange={handleQuestionValueChanges} />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group clearfix">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" value={questions.email} onChange={handleQuestionValueChanges} />
                                                </div>
                                                <div className="col-lg-12 col-12 form-group">
                                                    <textarea className="form-control" name="message" id="message" placeholder="Massage" value={questions.message} onChange={handleQuestionValueChanges} ></textarea>
                                                </div>
                                                <div className="submit-area col-lg-12 col-12">
                                                    <button type="submit" className="theme-btn submit-btn">Submit Now</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        )}
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
}

export default EventTabs;