import eimg1 from '../images/slider/meetup4.JPG'
import eimg2 from '../images/slider/meetup1.JPG'
import eimg3 from '../images/slider/meetup5.JPG'
import eimg4 from '../images/event/img-4.jpg'
import eimg5 from '../images/event/img-5.jpg'
import eimg6 from '../images/event/img-6.jpg'
import eimg7 from '../images/event/img-7.jpg'
import eimg8 from '../images/event/img-8.jpg'
import eimg9 from '../images/event/img-9.jpg'

const Events = [
    {
        id: '1',
        eImg:eimg1,
        date:"1st June, 2023 - Apply",
        eTitle: 'CS Academy 2023', 
        dec:'Learn mobile app devlopment with React Native and API backend with Python.',
        description: 'You will be introduced to mobile app development using React Native and backend development using Python Flask. At the end of the program, you will have technical hands-on experience on building and publishing APK for android and deploying backend API.',
        stack: {
            stack1: 'React Native',
            stack2: 'Python Flask',
            stack3: 'API',
            stack4: 'SqlAlchemy',
            stack5: 'Marchmallow',
            stack6: 'Password Tokens',
            stack7: 'and more...',
        },
        timeline: {
            submission: '1st - 14th June 2023, Application Submission',
            review: '15th - 21st June 2023, Application Review',
            interview: '22nd - 27th June 2023, Interviews',
            status: '29th June 2023, Application Status',
            launch: '1st July 2023, Program Launch',
        },
        prize: {
            first: 1000,
            second: 750,
            third: 500,
        },
        eventDate: {
            startDate: 'June 1st 2023',
            endDate: 'November 30th 2023'
        }
    },
    {
        id: '2',
        eImg:eimg2,
        date:"26th Nov. 2022 - Closed",
        eTitle: 'Innovation Exchange.', 
        dec:'Come and meet innovators like yourself and share experiences.',
        description: 'Coming soon...',
        stack: {
            stack1: 'Tedtalk',
            stack7: 'and more...',
        },
        timeline: {
            submission: '10th - 26th November 2022, Application Submission',
            review: '22th - 26th November 2022, Application Review',
            status: '1st December 2022, Status Emails',
            launch: '3rd December 2022, Program Launch',
        },
        eventDate: {
            startDate: '3rd December 2022',
            endDate: '3rd December 2022'
        }
    },
    {
        id: '3',
        eImg:eimg3,
        date:"20th Oct. 2022 - Closed",
        eTitle: 'ICT for Development', 
        dec:'Learn the tools need to implement everyday task.',
        description: 'Coming soon....',
        stack: {
            stack1: 'Oline Resources',
            stack7: 'and more...',
        },
        timeline: {
            submission: '1st - 20th October 2022, Application Submission',
            review: '23th - 27th October 2022, Application Review',
            status: '29th October 2022, Status Emails',
            launch: '3rd November 2022, Program Launch',
        },
        eventDate: {
            startDate: '3rd November 2022',
            endDate: '3rd November 2022'
        }
    },
    {
        id: '4',
        eImg:eimg1,
        date:"CS",
        eTitle: 'Monrovia CS Academy', 
        dec:'The CS Academy is an intensive six months coding training provided by CS4Liberia and Orange Digital Center. The program accelerates participants from beginners to advanced levels in programming, data science, computer science, and information technology paths. After completing the CS Academy, participants gain practical experience in computer programming and how to use IT project management tools to help them work smarter, create impact-driven projects that solve real problems.',
    },
    {
        id: '5',
        eImg:eimg2,
        date:"Meetup",
        eTitle: 'Monrovia Tech Meetup', 
        dec:'The Monrovia Tech Meetup is a regular tech meetup that brings industry leaders and stakeholders for deliberations on various curated tech-related topics. Participants have access to adequate knowledge, practical and hands-on experience that can be easily adapted to empower them to work independently on different range of projects. Additionally, this is also a networking event for all stakeholders in the Liberian tech industry.',
    },
    {
        id: '6',
        eImg:eimg3,
        date:"Training",
        eTitle: 'Online Training', 
        dec:'The online training programs target specific audiences and provide them with training resources online. Our curated training focuses on empowering and building the capacity of the next generation of Liberia’s tech leaders.',
    },
    {
        id: '7',
        eImg:eimg7,
        date:"25 Nov, 2021",
        eTitle: 'Love the planet earth to spend a happy life.', 
        dec:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
        id: '8',
        eImg:eimg8,
        date:"26 Nov, 2021",
        eTitle: 'Keep Calm and just Go Green!', 
        dec:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
    {
        id: '9',
        eImg:eimg9,
        date:"27 Nov, 2021",
        eTitle: 'Save The Wildlife Animal.', 
        dec:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
]

export default Events;