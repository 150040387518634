import React, {Fragment, useState} from 'react';
import axios from 'axios';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import pimg from '../../images/checkout/img-1.png'
import pimg2 from '../../images/checkout/img-2.png'
import pimg3 from '../../images/checkout/img-3.png'
import pimg4 from '../../images/checkout/img-4.png'
import { URL } from '../../components/constant';


const DonatePage =() => {

    const [submitted, setSubmitted] = useState(false)
    const [data, setData] = useState({
        amount: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
      })


      const changeHandler = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
          });
    }

    const SubmitHandler = (e) => {
        e.preventDefault()

        axios.post(`${URL}/donations`, {
            amount: data.amount,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            phone: data.phone,
            message: data.message
        }, {
          headers: { 'Content-Type': 'application/json' }
        }).then((res) => {      
            console.log(res.data)
            setSubmitted(true)
            setData({
                amount: '',
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                message: '',
            })
        }).catch((err) => {
            console.log(err.response)
        })
    }

    return(
        <Fragment>
            {/* <Navbar2 Logo={Logo}/> */}
            <PageTitle pageTitle={'Donate'} pagesub={'Donate'}/> 
                <div className="wpo-donation-page-area section-padding">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="wpo-donate-header">
                                    <h2>Make a Donation</h2>
                                    <h3>Leave your details and we will get in touch.</h3>
                                    {submitted ? <h3>Thank you, we will reach out to you for your donation.</h3> : ''}
                                </div>
                                <div id="Donations" className="tab-pane">
                                    <form onSubmit={SubmitHandler}>
                                        <div className="wpo-donations-amount">
                                            <h2>Your Donation</h2>
                                            <input type="text" className="form-control" name="amount" id="text" placeholder="Enter Donation Amount" value={data.amount} onChange={changeHandler} />
                                        </div>
                                        <div className="wpo-donations-details">
                                            <h2>Details</h2>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <input type="text" className="form-control" name="firstname" id="fname" placeholder="First Name" value={data.firstname} onChange={changeHandler} />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <input type="text" className="form-control" name="lastname" id="lname" placeholder="Last Name" value={data.lastname} onChange={changeHandler} />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Email" value={data.email} onChange={changeHandler} />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone" value={data.phone} onChange={changeHandler} />
                                                </div>
                                                <div className="col-lg-12 col-12 form-group">
                                                    <textarea className="form-control" name="message" id="note" placeholder="Message" value={data.message} onChange={changeHandler}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="wpo-doanation-payment">
                                            <h2>Choose Your Payment Method</h2>
                                            <div className="wpo-payment-area">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="wpo-payment-option" id="open4">
                                                            <div className="wpo-payment-select">
                                                                <ul>
                                                                    <li className="addToggle">
                                                                        <input id="add" type="radio" name="payment" value="30" />
                                                                        <label htmlFor="add">Payment By Card</label>
                                                                    </li>
                                                                    <li className="removeToggle">
                                                                        <input id="remove" type="radio" name="payment" value="30" />
                                                                        <label htmlFor="remove">Offline Donation</label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div id="open5" className="payment-name">
                                                                <ul>
                                                                    <li className="visa"><input id="1" type="radio" name="size" value="30" />
                                                                        <label htmlFor="1"><img src={pimg} alt="" /></label>
                                                                    </li>
                                                                    <li className="mas"><input id="2" type="radio" name="size" value="30" />
                                                                        <label htmlFor="2"><img src={pimg2} alt="" /></label>
                                                                    </li>
                                                                    <li className="ski"><input id="3" type="radio" name="size" value="30" />
                                                                        <label htmlFor="3"><img src={pimg3} alt="" /></label>
                                                                    </li>
                                                                    <li className="pay"><input id="4" type="radio" name="size" value="30" />
                                                                        <label htmlFor="4"><img src={pimg4} alt="" /></label>
                                                                    </li>
                                                                </ul>
                                                                <div className="contact-form form-style">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-12 col-12">
                                                                            <label>Card holder Name</label>
                                                                            <input type="text" placeholder="" name="name" />
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-12 col-12">
                                                                            <label>Card Number</label>
                                                                            <input type="text" placeholder="" id="card" name="card" />
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-12 col-12">
                                                                            <label>CVV</label>
                                                                            <input type="text" placeholder="" name="CVV" />
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-12 col-12">
                                                                            <label>Expire Date</label>
                                                                            <input type="text" placeholder="" name="date" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="submit-area">
                                            <button type="submit" className="theme-btn submit-btn">Donate Now</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* <Footer/> */}
            <Scrollbar/>
        </Fragment>
    )
};
export default DonatePage;

