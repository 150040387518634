import React from 'react'
import {Link} from  'react-router-dom'
import abimg from '../../images/slider/pic_six.JPG'
import shape from '../../images/ab-shape-2.png'


const About = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abimg} alt=""/>
                                <div className="wpo-ab-shape-1">
                                    <div className="s-s1"></div>
                                    <div className="s-s2"></div>
                                </div>
                                <div className="wpo-ab-shape-2"><img src={shape} alt=""/></div>
                                <div className="wpo-total-raised">
                                    <div className="wpo-total-raised-wrap">
                                        <div className="wpo-total-raised-icon">
                                            {/* <i className="fi flaticon-wallet-filled-money-tool"></i> */}
                                        </div>
                                        <div className="wpo-total-raised-text">
                                            <ul>
                                                <li>Tech and Innovation<span></span></li>
                                            </ul>
                                            <div className="progress-section">
                                                <div className="process">
                                                    <div className="progress">
                                                        <div className="progress-bar">
                                                            <div className="progress-value"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <span>About Us</span>
                            <h2>Building the capacity of the next generation of Liberian Software Engineers.</h2>
                            <p>CSforLiberia is a non-profit organization dedicated to increasing the 
                                number of computer scientist in Liberia.  We do this by building the 
                                capacity of the next generation of tech professionals in Liberia, 
                                through training, scholarships and access to high quality computer 
                                science content. </p>
                            <ul>
                                <li>Hands-on Training.</li>
                                <li>Innovation Exchange.</li>
                                <li>Meeting Tech Professionals.</li>
                            </ul>
                            <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">More About</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;