import himg1 from '../images/service-single/1.jpg'
import himg2 from '../images/service-single/2.jpg'
import himg3 from '../images/service-single/3.jpg'
import cimg from '../images/service-single/4.jpg'
import mimg from '../images/service-single/5.jpg'
import eimg from '../images/service-single/6.jpg'
import seimg from '../images/service-single/7.jpg'
import ptimg from '../images/service-single/8.jpg'
import rhimg from '../images/service-single/9.jpg'

import wimg1 from '../images/service-single/w1.jpg'
import wimg2 from '../images/service-single/w2.jpg'


import ocimg from '../images/service-single/10.jpg'
import ocimg2 from '../images/service-single/11.jpg'
import ocimg3 from '../images/service-single/12.jpg'
import ocimg4 from '../images/service-single/13.jpg'
import ocimg5 from '../images/service-single/14.jpg'

import wpimg1 from '../images/service-single/15.jpg'
import wpimg2 from '../images/service-single/16.jpg'
import wpimg3 from '../images/service-single/17.jpg'

import wpimg4 from '../images/service-single/wp1.jpg'
import wpimg5 from '../images/service-single/wp2.jpg'

import ntimg1 from '../images/service-single/18.jpg'
import ntimg2 from '../images/service-single/20.jpg'
import ntimg3 from '../images/service-single/19.jpg'

import ntimg4 from '../images/service-single/nt1.jpg'
import ntimg5 from '../images/service-single/nt2.jpg'


import picon1 from '../images/icon/1.png'
import picon2 from '../images/icon/2.png'
import picon3 from '../images/icon/3.png'



const Services = [
    {
        id: '1',
        fIcon1:'flaticon-graduation-cap',
        title:'CS Academy',
        description:'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
        simg1:himg1,
        simg2:himg2,
        simg3:himg3,
    },
    {
        id: '2',
        fIcon1:'flaticon-graduation-cap',
        title:'Meetup',
        description:'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
        simg1:cimg,
        simg2:himg2,
        simg3:himg3,
    },
    {
        id: '4',
        fIcon1:'flaticon-graduation-cap',
        title:'Online Training',
        description:'I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
        simg1:eimg,
        simg2:himg2,
        simg3:himg3,
    },
    {
        id: '5',
        fIcon1:'flaticon-graduation-cap',
        title:'Exchange',
        description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
        simg1:seimg,
        simg2:wimg1,
        simg3:wimg2,
    }
]
export default Services;