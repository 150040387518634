import React from "react";
import { Link } from 'react-router-dom'
import Clients from './client'


import hero1 from '../../images/slider/pic_one.JPG'
import hero2 from '../../images/slider/s.png'
import hero3 from '../../images/slider/pic_four.JPG'




const Hero =() => {
    return (
        <section className="wpo-hero-section-1">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col col-xs-6 col-lg-6">
                        <div className="wpo-hero-section-text">
                            <div className="wpo-hero-title-top">
                                <span>CSforLiberia's Vision</span>
                            </div>
                            <div className="wpo-hero-title">
                                <h2>Growing Liberia into a hub for technology and innovation.</h2>
                            </div>
                            <div className="wpo-hero-subtitle">
                                <p>Developing a Liberia that is technically advanced 
                                    with citizens that can compete in 
                                    the industry of the future.</p>
                            </div>
                            <div className="btns">
                                <Link to="/event" className="btn theme-btn-s2">Upcoming Events</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="right-vec">
                            <div className="right-items-wrap">
                                <div className="right-item">
                                    <div className="r-img">
                                        <img src={hero1} alt=""/>
                                    </div>
                                    <div className="wpo-happy-client">
                                        <Clients/>
                                    </div>
                                </div>
                                <div className="right-item">
                                    <div className="wpo-total-project">
                                        <div className="wpo-total-project-wrap">
                                            <div className="wpo-total-project-icon">
                                                {/* <i className="fi flaticon-hand"></i> */}
                                            </div>
                                            <div className="wpo-total-project-text">
                                                <h3>1000+</h3>
                                                <p>Students Affected</p>
                                            </div>
                                        </div>
                                        <div className="project-shape">
                                            <img src={hero2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="r-img">
                                        <img src={hero3} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}



export default Hero;
