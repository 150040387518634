import timg1 from '../images/team/eman.jpeg'
import timg2 from '../images/team/israel.jpeg'
import timg3 from '../images/team/abaka1.jpeg'
import timg4 from '../images/team/raph1.jpeg'
import timg5 from '../images/team/comrade.jpeg'
import timg6 from '../images/team/vermon.jpeg'
import timg7 from '../images/team/polo.jpeg'
import timg8 from '../images/team/moses.jpeg'
import timg9 from '../images/team/barry.jpeg'
import timg10 from '../images/team/10.jpg'


const Teams = [
    {
       id:'1',
       tImg:timg1,
       name:'Emmanuel Johnson',
       title:'Board Member',     
    },
    {
       id:'2',
       tImg:timg2,
       name:'Israel Z. Kollie',
       title:'Executive Director',     
    },
    {
       id:'3',
       tImg:timg3,
       name:'Daniel Abaka',
       title:'Head of Training',     
    },
    {
       id:'4',
       tImg:timg4,
       name:'Raphael Hanyor',
       title:'Trainer',     
    },
    {
       id:'5',
       tImg:timg5,
       name:'William Cole',
       title:'Researcher',     
    },
    {
       id:'6',
       tImg:timg6,
       name:'Vermon Washington',
       title:'Head, Content Creation',     
    },
    {
       id:'7',
       tImg:timg7,
       name:'Kelvin Gowah',
       title:'UX Designer',     
    },
    {
       id:'8',
       tImg:timg8,
       name:'Moses B. Kortu, Jr.',
       title:'Digital Strategy and  Analytics Lead', 
    },
    {
       id:'9',
       tImg:timg9,
       name:'Sulaiman Barry',
       title:'Trainer',     
    },
   //  {
   //     id:'10',
   //     tImg:timg10,
   //     name:'Zenefer Marry',
   //     title:'Manager',     
   //  }
    
]

export default Teams;