import React from 'react'
import {Link} from  'react-router-dom'
import abimg from '../../images/slider/pic_two.JPG'


const AboutS2 = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abimg} alt=""/>
                                <div className="wpo-total-raised">
                                    <div className="wpo-total-raised-wrap">
                                        <div className="wpo-total-raised-icon">
                                            {/* <i className="fi flaticon-wallet-filled-money-tool"></i> */}
                                        </div>
                                        <div className="wpo-total-raised-text">
                                            <ul>
                                                <li>Tech and Innovation<span></span></li>
                                            </ul>
                                            <div className="progress-section">
                                                <div className="process">
                                                    <div className="progress">
                                                        <div className="progress-bar">
                                                            <div className="progress-value"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="round-ball-1"></div>
                                <div className="round-ball-2"></div>
                                <div className="round-ball-3"></div>
                                <div className="round-ball-4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <span>About Us</span>
                            <h2>Inspiring and supporting Liberians passion for technology.</h2>
                            <p>CSforLiberia is a non-profit organization dedicated to increasing 
                                the number of computer scientist in Liberia.  We do 
                                this by building the capacity of the next generation of 
                                tech professionals in Liberia, through training, 
                                scholarships and access to high quality computer 
                                science content. </p>

                                <p>CSforLiberia’s mission is to inspire and support 
                                    Liberians passion for technology and to build the 
                                    capacity of the next generation of tech professionals 
                                    in Liberia.
                                 </p>

                                 <p>Our vision is to develop a Liberia that is technically advanced 
                                    with citizens that can compete in the industry of the 
                                    future. We hope to grow Liberia into a hub for 
                                    technology and innovation.
                                 </p>
                            {/* <ul>
                                <li>The standard chunk of Lorem Ipsum used since.</li>
                                <li>Randomised words which don't look even slightly believable.</li>
                                <li>Making this the first true generator on the Internet.</li>
                            </ul> */}
                            {/* <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">More About</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutS2;