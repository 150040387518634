import React, { useState } from 'react'
import { URL } from '../constant'
import axios from 'axios'


const ContactForm = () => {
    const [submitted, setSubmitted] = useState(false)
    const [data, setData] = useState({
        name: '',
        email: '',
        subject: '',
        lastname: '',
        events: '',
        notes: '',
        error: {}
      })


    const changeHandler = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
          });
    }

    const subimtHandler = (e) => {
        e.preventDefault();


        if (data.name === '') {
            data.error.name = "Please enter your name";
        }
        if (data.email === '') {
            data.error.email = "Please enter your email";
        }
        if (data.subject === '') {
            data.error.subject = "Please enter your subject";
        }
        if (data.lastname === '') {
            data.error.lastname = "Please enter your Lastname";
        }
        if (data.events === '') {
            data.error.events = "Select your event list";
        }
        if (data.notes === '') {
            data.error.notes = "Please enter your note";
        }

        if (data.error.name === '' && data.error.email === '' && data.error.email === '' && data.error.lastname === '' && data.error.subject === '' && data.error.events === '' && data.error.notes === '') {
            setData({
                name: '',
                email: '',
                subject: '',
                lastname: '',
                events: '',
                notes: '',
                error: {}
            })
        }

        axios.post(`${URL}/contacts`, {
            firstname: data.name,
            lastname: data.lastname,
            email: data.email,
            message: data.notes
        }, {
          headers: { 
            'Content-Type': 'application/json' 
        }
        }).then((res) => {      
            console.log(res.data)
            setSubmitted(true)
            setData({
                name: '',
                email: '',
                subject: '',
                lastname: '',
                events: '',
                notes: '',
                error: {}
            })
        }).catch((err) => {
            console.log(err.response)
        })
    }

    return(
        <div>
            {submitted ? <h2>Thank you for contacting us. We will get back to you.</h2> : (
                <form onSubmit={subimtHandler} className="form">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={data.name} onChange={changeHandler} type="text" name="name" placeholder="Firstname"/>
                            <p>{data.error.name ? data.error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={data.lastname} onChange={changeHandler} type="text" name="lastname" placeholder="Lastname"/>
                            <p>{data.error.lastname ? data.error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={changeHandler} value={data.email} type="email" name="email" placeholder="Email"/>
                            <p>{data.error.email ? data.error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <select className="form-control" onChange={changeHandler} value={data.subject} type="text" name="subject">
                                <option >Subject</option>
                                <option >CS4Lib</option>
                                <option >Aplication</option>
                                <option >Monrovia Meetup</option>
                                <option >CS Academy</option>
                            </select>
                            <p>{data.error.subject ? data.error.subject : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea name="notes" placeholder="Message" value={data.notes} onChange={changeHandler} ></textarea>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button disabled={data.email.length === 0 || data.lastname.length === 0 || data.name.length === 0 || data.notes.length === 0} type="submit" className="theme-btn">Send Message</button>
                        </div>
                    </div>
                </div>
            </form>
            )}
        </div>
        )

}
export default  ContactForm;